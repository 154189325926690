import React from "react"
import { graphql, Link } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  PageWrapper,
  TextWrapper,
  HeadingWrapper,
  FlexWrapper,
  FlagWrapper,
} from "../components/contentwrappers"
import ActionButton from "../components/actionbutton"

import Img from "gatsby-image"

export default function GuidesPage({ data }) {
  const { guides } = data.guide

  return (
    <div>
      <BackgroundImage
        fluid={data.royalCourtsImage.childImageSharp.fluid}
        alt="The Royal Courts of Justice"
        style={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "-9",
        }}
      ></BackgroundImage>

      <Layout>
        <SEO title="Guides" />
        <PageWrapper>
          <HeadingWrapper>
            <h1>
              <strong>Guides</strong>
            </h1>
          </HeadingWrapper>

          <TextWrapper>
            <p>
              As well as our{" "}
              <Link to="/finding-true-wealth">blog articles</Link>, we produce
              guides from time-to-time. You can find previous guides below:
            </p>
          </TextWrapper>

          <FlexWrapper blogs>
            {guides.map(guide => (
              <FlagWrapper key={guide.id} width={"narrow"}>
                <h3>{guide.frontmatter.title}</h3>

                <small>{guide.frontmatter.date}</small>
                <a href={guide.frontmatter.fileUrl} target="_blank">
                  <Img
                    fluid={
                      guide.frontmatter.featuredImage.childImageSharp.fluid
                    }
                  />
                </a>
                <p></p>
              </FlagWrapper>
            ))}
          </FlexWrapper>
        </PageWrapper>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query GuideQuery {
    guide: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "guide" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      guides: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM YYYY")
          title
          fileUrl
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 424) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        excerpt
        id
      }
    }

    royalCourtsImage: file(
      relativePath: { eq: "royal-courts-of-justice.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 5031, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
